<template>
  <v-menu
    :close-on-content-click="false"
    :disabled="disabled"
    left
    offset-y
    min-width="320"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-text-field
          :value="formattedValue"
          readonly
          outlined
          hide-details
          :append-icon="getAppendIcon(attrs)"
          suffix="%"
          dense
        />
      </div>
    </template>

    <v-card class="px-5 py-2">
      <v-radio-group v-model="targetEnabled" @change="onChange">
        <v-row>
          <v-col>
            <v-radio label="Target" :value="true" class="mb-0" />
          </v-col>
          <v-col>
            <v-radio label="No Target" :value="false" />
          </v-col>
        </v-row>
      </v-radio-group>

      <v-divider></v-divider>

      <div class="d-flex justify-space-between align-center my-2">
        <span class="text-subtitle-1 font-weight-bold">Target</span>
        <v-switch
          v-model="rangeEnabled"
          label="Range"
          class="mt-0"
          hide-details
          :disabled="!targetEnabled"
          dense
          @change="onChange"
        />
      </div>

      <v-range-slider
        v-if="rangeEnabled"
        v-model="targetRange"
        :min="min"
        :max="max"
        :disabled="!targetEnabled"
        hide-details
        @change="onChange"
      />
      <v-slider
        v-else
        v-model="target"
        :min="min"
        :max="max"
        :disabled="!targetEnabled"
        hide-details
        @change="onChange"
      />
      <div class="d-flex justify-space-between">
        <span>{{ min }}</span>
        <span>{{ max }}</span>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    value: {
      type: Object,
      default: () => ({ min: 0, max: 100 }),
      validator: (value) => {
        // Applies "null" or "{ min: Integer, max: Integer }"
        if (!value) return true;
        return (
          typeof value === "object" &&
          Number.isInteger(value.min) &&
          Number.isInteger(value.max)
        );
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      target: 100,
      targetRange: [20, 80],
      targetEnabled: true,
      rangeEnabled: false,
    };
  },
  computed: {
    formattedValue() {
      if (!this.targetEnabled) {
        return "-";
      }
      if (this.rangeEnabled) {
        const [min, max] = this.targetRange;
        return `${min} - ${max}`;
      }
      return `${this.target}`;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.targetEnabled = !!this.value;
      if (!this.value) return;

      const { min, max } = this.value;
      this.rangeEnabled = min !== max;
      this.target = max;
      this.targetRange = [min, max];
    },
    getAppendIcon(attrs) {
      return attrs["aria-expanded"] === "true"
        ? "mdi-menu-up"
        : "mdi-menu-down";
    },
    onChange() {
      if (!this.targetEnabled) {
        return this.$emit("input", null);
      }
      if (this.rangeEnabled) {
        const [min, max] = this.targetRange;
        return this.$emit("input", { min, max });
      }
      const [min, max] = [this.target, this.target];
      return this.$emit("input", { min, max });
    },
  },
};
</script>
