<template>
  <v-main class="AuditTemplatePage">
    <div v-if="formData" class="app-container pt-10 pb-2">
      <v-row no-gutters>
        <v-col class="md-12">
          <breadcrumbs :items="breadcrumbs" />
        </v-col>
      </v-row>

      <section class="d-flex justify-space-between align-center py-4 mb-5">
        <v-card width="100%" class="header-card pa-5 mt-4" flat>
          <v-row>
            <v-col class="d-flex align-center">
              <v-text-field
                v-model="formData.name"
                :hide-details="!nameErrors.length"
                :error-messages="nameErrors"
                class="pt-0 text-h5 font-weight-bold"
                @input="onNameChanged"
                @blur="$v.formData.name.$touch()"
              />
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <v-btn
                :disabled="!this.$v.$anyDirty"
                color="primary"
                text
                @click="onReset"
              >
                Reset
              </v-btn>
              <v-btn
                color="primary"
                variant="elevated"
                rounded
                normal
                :disabled="!canSubmit"
                @click="onSubmit"
              >
                Save & Create
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </section>

      <section v-if="isDocReview && !isNew">
        <v-card width="100%" class="header-card pa-5 mt-4" flat>
          <v-row>
            <v-col class="d-flex align-center">
              <ClinicalServicesMultiselect
                :services="selectedServices"
                :audit-id="auditTemplate._id"
                @change="onServicesChange($event)"
              />
            </v-col>
            <v-col class="d-flex align-center justify-end">
              <v-btn
                color="primary"
                variant="elevated"
                rounded
                normal
                :disabled="!(hasServiceChanged && selectedServices.length)"
                @click="onUpdateServices"
              >
                Assign services
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </section>

      <AuditTemplateConditions
        v-if="formData.auditType !== BatchTypes.general && !isDocReview"
        v-model="formData.conditions"
      />

      <v-card class="evaluation-card pa-6 mt-6" flat>
        <v-row column>
          <v-col lg="5">
            <h6 class="text-h6">Audit questions</h6>
            <p class="text--secondary">
              Set up question blocks for this type of audit. Each question
              implies the target of getting the answer Yes.
            </p>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <v-btn
              color="primary"
              variant="elevated"
              rounded
              :disabled="isDocReview && !isNew"
              @click="onAddSection"
            >
              <v-icon left>mdi-plus</v-icon>
              Add Block
            </v-btn>
          </v-col>
        </v-row>

        <EvaluationSections
          v-model="formData.evaluation.sections"
          :validation="$v.formData.evaluation.sections"
          :audit-type="formData.auditType"
          :disabled="isDocReview && !isNew"
          @remove="onRemoveCriteria"
          @onSectionRemove="sectionRemove"
        />

        <v-btn
          color="primary"
          text
          rounded
          :disabled="!sections.length"
          @click="onAddCriteria"
        >
          <v-icon left>mdi-plus</v-icon>
          Add question
        </v-btn>
      </v-card>

      <section class="mt-6 mb-4">
        <h3 class="text-h6 mb-4">Audit Documentation</h3>
        <v-row>
          <v-col lg="6" md="9" sm="12">
            <FileCard
              v-if="formData.documentationFile"
              :file="formData.documentationFile"
              @close="onFileClose"
            />
            <v-form v-else>
              <FileInputArea
                icon="mdi-file-upload-outline"
                :disabled="isDocReview && !isNew"
                :value="file"
                :error-messages="fileErrors"
                @change="onFileChange"
              >
                <div class="d-flex">
                  <label
                    for="file-input"
                    class="text-capitalize text-subtitle-1"
                  >
                    Click to upload
                  </label>
                  &nbsp;
                  <p class="text-subtitle-1">or drag and drop</p>
                </div>
                <span class="text-subtitle-1 text--secondary">
                  PDF, DOC or XLS (max. 3MB)
                </span>
              </FileInputArea>
            </v-form>
          </v-col>
        </v-row>
      </section>
    </div>
  </v-main>
</template>

<script>
import ObjectId from "bson-objectid";
import { cloneDeep, debounce, isEqual } from "lodash";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import FileCard from "@/components/common/FileCard.vue";
import dateToLocalString from "@/helpers/dateToLocalString";
import AuditTemplateConditions from "@/components/auditTemplate/AuditTemplateConditions.vue";
import EvaluationSections from "@/components/auditTemplate/EvaluationSections.vue";
import FileInputArea from "@/components/controls/FileInputArea.vue";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";
import { BatchTypes } from "@/misc/constants";

import {
  getAuditTemplate,
  updateAuditTemplate,
  createAuditTemplate,
  uploadDocumentation,
  updateAuditServices,
} from "@/services/auditTemplates";
import { getClinicalServices } from "@/services/clinicalServices";

const initialFormData = {
  name: "",
  conditions: {
    clinicalCodes: [],
    age: null,
    prescriptions: null,
  },
  evaluation: {
    sections: [],
  },
  documentationFile: null,
};

export default {
  name: "AuditTemplate",

  components: {
    Breadcrumbs,
    FileCard,
    AuditTemplateConditions,
    EvaluationSections,
    FileInputArea,
    ClinicalServicesMultiselect,
  },

  mixins: [validationMixin],

  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(80),
      },
      evaluation: {
        sections: {
          required,
          hasCriteria: (sections) =>
            !sections || sections.some((section) => section.criteria.length),
          $each: {
            criteria: {
              $each: {
                text: {
                  required,
                  minLength: minLength(1),
                },
                options: {
                  required: requiredIf(function () {
                    return this.formData.auditType === BatchTypes.general;
                  }),
                },
              },
            },
          },
        },
      },
      documentationFile: {},
      conditions: {},
    },
  },

  data() {
    const breadcrumbs = [
      {
        title: "Audit Templates",
        disabled: false,
        to: "/audit-templates",
      },
    ];

    return {
      BatchTypes,
      breadcrumbs,
      auditTemplate: null,
      formData: null,
      file: null,
      fileErrors: [],
      hasServiceChanged: false,
      selectedServices: [],
    };
  },

  computed: {
    templateId() {
      return this.$route.params?.id;
    },
    sections() {
      return this.formData.evaluation?.sections || [];
    },
    nameErrors() {
      const field = this.$v.formData.name;
      if (!field.$dirty) return [];

      const errors = [];
      !field.minLength && errors.push("Must be at least 3 characters long");
      !field.maxLength && errors.push("Must be at most 80 characters long");
      !field.required && errors.push("Name is required.");
      return errors;
    },
    canSubmit() {
      if (!this.$v.formData.$anyDirty) return false;
      return !this.$v.$invalid;
    },
    isDocReview() {
      return this.auditTemplate?.auditType === BatchTypes.docReview;
    },
    isNew() {
      return this.templateId === "new";
    }
  },

  watch: {
    "formData.evaluation.sections": {
      handler(newV) {
        this.onEvaluationChange(newV);
      },
      deep: true,
    },
    "formData.conditions": {
      handler(newV) {
        this.onConditionsChange(newV);
      },
      deep: true,
    },
  },

  async mounted() {
    await this.fetchClinicalServices();
    if (this.templateId !== "new") {
      return this.fetchAuditTemplate(this.templateId);
    }

    this.formData = {
      ...cloneDeep(initialFormData),
      name: this.$route.query.templateName,
      auditType: this.$route.query.auditType,
    };

    this.breadcrumbs = [...this.breadcrumbs, { title: "New", disabled: true }];
    this.auditTemplate = cloneDeep(this.formData);
    this.$v.$reset();
  },

  methods: {
    dateToLocalString,
    async fetchAuditTemplate(templateId) {
      try {
        this.formData = await getAuditTemplate(templateId);
        this.auditTemplate = cloneDeep(this.formData);

        this.breadcrumbs = [
          ...this.breadcrumbs,
          { title: this.formData.name, disabled: true },
        ];
      } catch (error) {
        this.$notify({ type: "error", text: error.message });
      }
    },
    async fetchClinicalServices() {
      try {
        const services = await getClinicalServices();
        this.selectedServices = services
          .map((service) => this.templateId && this.templateId === service.templateId ? service._id : null)
          .filter(Boolean);
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Service fetch failed",
          text: error.response?.data?.message || error.message,
        });
      }
    },
    onAddSection() {
      const section = {
        _id: ObjectId().toHexString(),
        title: "",
        criteria: [],
      };
      this.formData.evaluation.sections.push(section);
    },
    onAddCriteria() {
      const sections = this.formData.evaluation.sections;
      const criteria = {
        _id: ObjectId().toHexString(),
        text: "",
        target: null,
        ...(this.formData.auditType === BatchTypes.general && {
          options: ["Yes", "No"],
        }),
        ...(this.formData.auditType === BatchTypes.docReview && {
          options: ["0", "1", "2", "N/A"],
        }),
      };

      sections[sections.length - 1]?.criteria?.push(criteria);
    },
    onRemoveCriteria({ sectionIndex, criteriaIndex }) {
      const criteria = this.formData.evaluation.sections[sectionIndex].criteria;
      const filteredCriteria = criteria.filter(
        (_, index) => index !== criteriaIndex
      );

      this.$set(
        this.formData.evaluation.sections[sectionIndex],
        "criteria",
        filteredCriteria
      );
    },
    sectionRemove({ sectionId }) {
      const filteredSections = this.formData.evaluation.sections.filter(section => section._id !== sectionId);
      this.formData.evaluation.sections = filteredSections;
    },
    removeEmptySections() {
      this.formData.evaluation.sections =
        this.formData.evaluation.sections.filter(
          (section) => !!section.criteria?.length
        );
    },
    onFileChange(file) {
      const documentationFile = this.$v.formData.documentationFile;
      this.file = file;
      return file ? documentationFile.$touch() : documentationFile.$reset();
    },
    onFileClose() {
      this.formData.documentationFile = null;
      this.$v.formData.documentationFile.$touch();
    },
    onReset() {
      this.formData = cloneDeep(this.auditTemplate);
      this.$v.formData.$reset();
    },
    onEvaluationChange: debounce(function (newValue) {
      this.$v.formData.evaluation.$touch();
      if (this.templateId === "new") return;

      const isDataChanged = this.auditTemplate.evaluation.sections.reduce(
        (changed, section, index) => {
          if (!isEqual(section, newValue[index])) {
            return true;
          }
          return changed;
        },
        false
      );

      if (!isDataChanged) {
        this.$v.formData.evaluation.$reset();
      }
    }, 500),
    onNameChanged() {
      const { name } = this.$v.formData;
      name.$touch();

      if (
        !name.$invalid &&
        name.$dirty &&
        this.formData.name === this.auditTemplate.name
      ) {
        name.$reset();
      }
    },
    onConditionsChange: debounce(function (newValue) {
      const conditions = this.$v.formData.conditions;
      conditions.$touch();
      if (this.templateId === "new") return;
      isEqual(this.auditTemplate?.conditions, newValue) && conditions.$reset();
    }, 500),
    async onSubmit() {
      this.removeEmptySections();

      if (this.formData._id) {
        await this.update();
        this.$router.push({ path: `/audit-templates/${this.formData._id}` });
      } else {
        await this.create();
        this.$router.push({ path: "/audit-templates" });
      }
    },
    async onUpdateServices() {
      try {
        await updateAuditServices(this.auditTemplate._id, this.selectedServices);
        this.$notify({
          type: "success",
          title: "Services updated",
        });
        this.$v.$reset();
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Failed to update services",
          text: error.response?.data?.message || error.message,
        });
      }
      this.hasServiceChanged = false;
    },
    async create() {
      try {
        if (this.file) {
          const file = await uploadDocumentation(this.file);
          this.formData.documentationFile = file;
        }

        await createAuditTemplate(this.formData);
        this.$notify({
          type: "success",
          title: "Audit template created",
        });
        this.$v.$reset();
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Failed to create Audit Template",
          text: error.response?.data?.message || error.message,
        });
      }
    },
    onServicesChange(data) {
      this.hasServiceChanged = true;
      this.selectedServices = data || [];
    },
    async update() {
      try {
        if (this.file) {
          const file = await uploadDocumentation(this.file);
          this.formData.documentationFile = file;
        }
        this.auditTemplate = await updateAuditTemplate(
          this.formData._id,
          this.formData
        );
        this.$notify({
          type: "success",
          title: "Audit Template updated",
        });
        this.$v.$reset();
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Failed to update Audit Template",
          text: error.response?.data?.message || error.message,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.AuditTemplatePage {
  .header-card {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
  }

  .evaluation-card {
    background-color: #ebfafb;
    border: 1px solid #01c6d0;
    border-radius: 8px;
  }

  &-Toolbar {
    .v-text-field {
      .v-input__slot::before {
        border: none !important;
      }
    }
  }
}
</style>
