<template>
  <draggable
    v-model="sections"
    :disabled="disabled"
    group="sections"
    handle=".handle"
    class="mt-4"
  >
    <div v-for="(section, sectionIndex) in sections" :key="section._id">
      <v-card
        class="pa-2 mb-2"
        color="rgba(1, 198, 208, 0.16)"
        flat
        style="border-radius: 8px"
      >
        <v-row>
          <v-col lg="9" md="8" class="d-flex align-center">
            <v-icon class="handle mr-3">mdi-drag-vertical</v-icon>
            <v-text-field
              v-model="section.title"
              :disabled="disabled"
              placeholder="Block Title"
              class="pa-0 ma-0"
              hide-details
            />
          </v-col>
          <v-col lg="1" md="4" class="d-flex align-center">
            <span class="font-weight-bold">{{ sectionTypeTitle }}</span>
          </v-col>
          <v-col lg="2" md="4" class="d-flex align-center justify-end">
            <v-btn
              :disabled="disabled"
              class="mx-5"
              icon
              @click="onRemoveSection(section._id)"
            >
              <v-icon class="handle">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <draggable
        v-model="sections[sectionIndex].criteria"
        :disabled="disabled"
        group="criteria"
        handle=".handle"
      >
        <v-row
          v-for="(criteria, index) in section.criteria"
          :key="criteria._id"
          class="pl-2 my-1"
          style="margin: 0 -12px"
        >
          <v-col lg="9" md="8" class="criteria-row d-flex align-center">
            <v-icon class="handle mr-3">mdi-drag-vertical</v-icon>
            <v-text-field
              v-model="criteria.text"
              :disabled="disabled"
              :error-messages="getCriteriaTextErrorMessage(sectionIndex, index)"
              placeholder="Audit question"
              class="pa-0 ma-0"
              hide-details="auto"
              persistent-hint
            />
          </v-col>
          <v-col lg="3" md="4" class="d-flex">
            <TargetInput
              v-if="isSpecialAudit"
              v-model="criteria.target"
              :disabled="disabled"
            />
            <v-select
              v-if="!isSpecialAudit"
              v-model="criteria.options"
              :items="
                auditType === BatchTypes.general
                  ? generalAuditOptions
                  : clinicalDocOptions
              "
              :disabled="isDocReview"
              item-text="title"
              item-value="value"
              :menu-props="{ bottom: true, offsetY: true }"
              label="Answer Options"
              style="max-width: 180px"
            />
            <v-btn
              :disabled="disabled"
              class="mx-5"
              icon
              @click="onRemove(sectionIndex, index)"
            >
              <v-icon class="handle">mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </draggable>
    </div>
  </draggable>
</template>

<script>
import Draggable from "vuedraggable";
import TargetInput from "./TargetInput.vue";
import {
  BatchTypes,
  GeneralAuditOptionsArray,
  CLinicalDocOptionsArray,
} from "@/misc/constants";

export default {
  components: {
    Draggable,
    TargetInput,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      default: null,
    },
    auditType: {
      type: String,
      default: "Special Audit",
    },
  },

  data() {
    return {
      BatchTypes,
      generalAuditOptions: GeneralAuditOptionsArray.map((opt) => {
        return {
          title: opt.join(", "),
          value: opt,
        };
      }),
      clinicalDocOptions: CLinicalDocOptionsArray.map((opt) => {
        return { title: opt.join(", "), value: opt };
      }),
    };
  },

  computed: {
    sections: {
      get() {
        return this.value;
      },
      set(sections) {
        this.$emit("input", sections);
      },
    },
    criteriaValidations() {
      if (!this.validation) return null;
      return this.validation?.$each;
    },
    sectionTypeTitle() {
      return this.auditType === BatchTypes.general || BatchTypes.docReview
        ? "Answers"
        : "Target";
    },
    isSpecialAudit() {
      return this.auditType === BatchTypes.specialAudit;
    },
    isDocReview() {
      return this.auditType === BatchTypes.docReview;
    }
  },

  methods: {
    onRemove(sectionIndex, criteriaIndex) {
      this.$emit("remove", { sectionIndex, criteriaIndex });
    },
    onRemoveSection(sectionId) {
      this.$emit("onSectionRemove", { sectionId });
    },
    getCriteriaTextErrorMessage(criteriaIndex, textIndex) {
      if (!this.criteriaValidations) return [];
      const criteria = this.criteriaValidations[criteriaIndex]?.criteria?.$each;
      if (!criteria) return [];
      const textValidation = criteria[textIndex]?.text;
      if (!textValidation) return [];
      return textValidation?.$error && textValidation?.minLength
        ? [
            `Should have at least ${textValidation?.$params?.minLength?.min} character`,
          ]
        : [];
    },
  },
};
</script>

<style lang="scss">
.handle {
  cursor: pointer;
}

.criteria-row {
  .handle,
  .order {
    width: 30px;
  }
  .handle {
    display: none;
  }
  .order {
    display: block;
  }

  &:hover {
    .handle {
      display: block;
    }
    .order {
      display: none;
    }
  }
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.24);
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row;
  gap: 20px;

  .v-radio {
    margin: 0 !important;
  }
}
</style>
