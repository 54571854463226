<template>
  <section>
    <h1 class="text-h6 pb-5">Filters</h1>
    <v-row class="mb-2">
      <v-col md="4">
        <v-autocomplete
          ref="clinicalCodeSelect"
          v-model="clinicalCode"
          :loading="clinicalCodesLoading"
          :items="clinicalCodeOptions"
          :item-text="getClinicalCodeLabel"
          item-value="_id"
          label="Clinical Codes"
          hint="To add code press “Enter”"
          clearable
          outlined
          return-object
          persistent-hint
          @click:clear="fetchClinicalCodeOptions"
          @update:search-input="onClinicalCodeSearch"
          @keydown.enter="onAddClinicalCode"
        />
      </v-col>
      <v-col md="2">
        <RangePicker :value="age" @change="onAgeChange" />
      </v-col>
      <v-col md="3">
        <v-text-field
          :value="prescriptions"
          label="Prescription"
          placeholder="Enter your text"
          outlined
          clearable
          @input="onPrescriptionsInput"
        />
      </v-col>
    </v-row>

    <v-chip
      v-for="code in clinicalCodes"
      :key="code.code"
      class="mr-2 mb-2"
      close-icon="mdi-close-circle"
      close
      label
      @click:close="onRemoveClinicalCode(code._id)"
    >
      {{ code.code }} {{ code.label }}
    </v-chip>
  </section>
</template>

<script>
import { keyBy, debounce } from "lodash";
import RangePicker from "@/components/controls/RangePicker.vue";
import { getClinicalCodes } from "@/services/clinicalCodes";

export default {
  components: {
    RangePicker,
  },

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  data() {
    return {
      clinicalCode: null,
      clinicalCodesMap: {},
      clinicalCodeOptions: [],
      clinicalCodesLoading: false,
      age: null,
      prescriptions: null,
    };
  },

  computed: {
    clinicalCodes() {
      return this.value.clinicalCodes?.map(this.getClinicalCodeById);
    },
  },

  watch: {
    async value(newValue) {
      await this.initialize(newValue);
    },
  },

  async mounted() {
    await Promise.all([
      this.initialize(this.value),
      this.fetchClinicalCodeOptions(),
    ]);
  },

  methods: {
    async initialize(value = {}) {
      const { age, prescriptions, clinicalCodes } = value;
      if (clinicalCodes?.length) {
        await this.fetchClinicalCodesByIds(this.value?.clinicalCodes);
      }
      if (age && isFinite(age?.min)) {
        const { min, max } = age;
        this.age = `${min}` + (min !== max ? `-${max}` : "");
      } else {
        this.age = "";
      }
      this.prescriptions = prescriptions;
    },
    async fetchClinicalCodesByIds(ids) {
      const filter = { _id: { $in: ids } };
      try {
        const response = await getClinicalCodes({ filter, pageSize: 100 });
        this.clinicalCodesMap = keyBy(response.data, "_id");
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Clinical Codes",
          text: error.message,
        });
      }
    },
    async fetchClinicalCodeOptions(search) {
      this.clinicalCodesLoading = true;
      const filter = { _id: { $nin: this.value.clinicalCodes } };

      if (search?.length) {
        const regex = { $regex: search, $options: "i" };
        filter["$or"] = [{ code: regex }, { label: regex }];
      }
      try {
        const response = await getClinicalCodes({ filter });
        this.clinicalCodeOptions = response.data;
      } catch (error) {
        this.$notify({
          type: "error",
          title: "Clinical Codes",
          text: error.message,
        });
      }

      this.clinicalCodesLoading = false;
    },
    getClinicalCodeLabel({ code, label }) {
      return `${code} ${label}`;
    },
    getClinicalCodeById(_id) {
      const unknownCode = { _id, code: _id, label: "Unknown" };
      return this.clinicalCodesMap[_id] || unknownCode;
    },
    onClinicalCodeSearch: debounce(function (search) {
      if (search === null || search.includes(" - ")) return;
      this.fetchClinicalCodeOptions(search);
    }, 1000),
    onAddClinicalCode() {
      const codeId = this.clinicalCode._id;
      this.clinicalCodesMap[codeId] = this.clinicalCode;

      const clinicalCodes = [...(this.value?.clinicalCodes || []), codeId];
      this.update("clinicalCodes", clinicalCodes);

      this.clinicalCode = null;
      this.clinicalCodeOptions = this.clinicalCodeOptions.filter(
        ({ _id }) => _id !== codeId
      );

      this.$refs.clinicalCodeSelect?.blur();
    },
    onRemoveClinicalCode(codeId) {
      const clinicalCodes = this.value.clinicalCodes.filter(
        (id) => id !== codeId
      );
      this.update("clinicalCodes", clinicalCodes);
    },
    onAgeChange(value) {
      this.age = value;
      if (value) {
        const [min, max] = value.split("-");
        return this.update("age", { min, max: max || min });
      }
      this.update("age", value);
    },
    onPrescriptionsInput: debounce(function (value) {
      this.update("prescriptions", value);
    }, 500),
    update(field, value) {
      const initialData = this.value || {};
      this.$emit("input", { ...initialData, [field]: value });
    },
  },
};
</script>
